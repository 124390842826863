import React from "react";
import shortid from  "shortid";

const TribeticSection3 = (props) => {
    return(
        <section className="section-3">
            <div className="container">
                <div className="title-and-description-block">
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-18">
                            <h1 className="h1 section-title">{props.mainTitle}</h1>
                            <p className="section-description">{props.mainDescription}</p>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>
                </div>
                <div className="steps">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-20">
                            {props.stepList?.map((object, i) => {
                                return (
                                    <div className="step" key={shortid.generate()}>
                                        <h3 className="h3 large section-title">{object.title}</h3>
                                        <div className="image-block">
                                            <img
                                                src={object.image1x?.sourceUrl}
                                                srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                                width={object.image1x?.width}
                                                alt={object.image1x?.altText}
                                            />
                                        </div> 
                                    </div>     
                                )
                            })}
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TribeticSection3;